<template>
  <Login
    :show="showLoginModal"
    :email="prefilledEmail"
    @hide="showLoginModal = false"
    @show-registration-modal="registrationModal"
    @show-reset-password-modal="resetPasswordModal"
    @show-sms-login-modal="smsLoginModal"
  />

  <SmsLogin
    :show="showSmsLoginModal"
    @hide="showSmsLoginModal = false"
    @show-registration-modal="registrationModal"
    @show-reset-password-modal="resetPasswordModal"
    @show-login-modal="loginModal"
  />

  <Registration
    :show="showRegistrationModal"
    @hide="showRegistrationModal = false"
    @show-login-modal="loginModal"
  />

  <ResetPassword
    :show="showResetPasswordModal"
    @hide="showResetPasswordModal = false"
    @show-login-modal="loginModal"
  />
</template>

<script setup>
import Login from '@/Front/Modals/Login.vue';
import Registration from '@/Front/Modals/Registration.vue';
import ResetPassword from '@/Front/Modals/ResetPassword.vue';
import SmsLogin from '@/Front/Modals/SmsLogin.vue';
import { ref } from 'vue';

const showLoginModal = ref(false);
const showSmsLoginModal = ref(false);
const showRegistrationModal = ref(false);
const showResetPasswordModal = ref(false);
const prefilledEmail = ref(false);

const searchParams = new URLSearchParams(window.location.search);
if (searchParams.get('login') && searchParams.get('social')) {
  loginModal(searchParams.get('email'));
} else if (searchParams.get('login')) {
  smsLoginModal();
}

function loginModal(email = null) {
  showLoginModal.value = true;
  showSmsLoginModal.value = false;
  showResetPasswordModal.value = false;
  showRegistrationModal.value = false;
  prefilledEmail.value = email;
}

function smsLoginModal() {
  showSmsLoginModal.value = true;
  showLoginModal.value = false;
  showResetPasswordModal.value = false;
  showRegistrationModal.value = false;
  prefilledEmail.value = null;
}

window.addEventListener('show-sms-login-modal', () => {
  smsLoginModal();
});

function registrationModal() {
  showRegistrationModal.value = true;
  showLoginModal.value = false;
  showSmsLoginModal.value = false;
  showResetPasswordModal.value = false;
  prefilledEmail.value = null;
}

window.addEventListener('show-registration-modal', () => {
  registrationModal();
});

function resetPasswordModal() {
  showResetPasswordModal.value = true;
  showLoginModal.value = false;
  showSmsLoginModal.value = false;
  showRegistrationModal.value = false;
  prefilledEmail.value = null;
}

window.addEventListener('show-reset-password-modal', () => {
  resetPasswordModal();
});
</script>
